import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image';


const NewsList = () => {

    const data = useStaticQuery(graphql`
        query{
            article:allContentfulArticle(sort: {fields: date, order: DESC}, limit: 6) {
                edges {
                  node {
                    slug
                    tItle
                    featuredImage {
                      fluid(maxWidth: 2000) {
                        ...GatsbyContentfulFluid
                      }
                    }
                    date(formatString: "DD MMMM YYYY")
                    contentful_id
                  }
                }
            }
        }
    `)

    return ( 
        <Wrapper>
            <div style={{margin: "0 auto", width: "100%", display: "flex", justifyContent: "center" }}>
                <NewsLink to="/articles"><h1 className="newslink">Latest Articles</h1></NewsLink>
            </div>
            <BlogIndex >
                    {data.article.edges.map((edge)=>{
                        return (
                            <div style={{borderRadius: "2px", overflow: "hidden"}}>
                            <BackgroundImage fluid={edge.node.featuredImage.fluid} style={{borderRadius: "15px !important"}}>
                            <BlogContainer key={edge.node.contentful_id} to={`/${edge.node.slug}`} style={{borderRadius: "15px"}}>
                                <BlogBlurb >
                                    <PostTitle >
                                            <h1 style={{ fontFamily: "'Cormorant Garamond', serif" }} className="blog-title">{edge.node.tItle}</h1>
                                        <p style={{ fontFamily: "'Montserrat', sans-serif", scrollPaddingBottom: "20px" }} className="blog-date">{edge.node.date}</p>

                                    </PostTitle>
                                </BlogBlurb>

                            </BlogContainer>
                            </BackgroundImage>
                            </div>
                        )
                    })}

            </BlogIndex>
            <div style={{margin: "30px auto 0 auto", width: "100%", display: "flex", justifyContent: "center" }}>
                <BottomNewsLink to="/articles"><h1 className="newslink">View all</h1></BottomNewsLink>
            </div>

        </Wrapper>
     );
}
 
export default NewsList;

const Wrapper = styled.section`
    padding: 60px 0;
    background-color: #ffffff;
    @media (max-width: 769px) {
        padding: 30px 0;
      }
`

const NewsLink = styled(Link)`
    margin: 0 auto;
    text-decoration: none !important;
    color:rgba(0, 66, 37, 1);
    font-family: 'Raleway', sans-serif !important;

    .newslink{
        font-family: 'Raleway', sans-serif !important;
        font-weight: 800;
        text-align: center;
        font-size: 38px;
        margin-bottom: 30px !important;
    }

    @media (max-width: 769px) {
        .newslink{
            font-family: 'Raleway', sans-serif !important;
            font-size: 25px;
        }
      }
`    

const BlogIndex = styled.div`
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-template-rows: auto;
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr !important;
    }
    @media (max-width: 450px) {
        grid-template-columns: 1fr !important;
        padding-left: 20px;
        padding-right: 20px;
    }
`

const BlogContainer = styled(Link)`
    display: block flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    background-color: rgba(0, 66, 37, 0.4);
    font-family: 'Cormorant Garamond', serif !important;

    
    border-radius: 2px !important;
    overflow: hidden;

`

const BlogImage = styled(Img)`
    width: 100%;
    

`

const BlogBlurb = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    justify-content: center;
    background-color: rgba(0, 66, 37, 0.4);
    font-family: 'Cormorant Garamond', serif !important;
    padding: 20px;
    flex: 1;
    transition: all 300ms linear 0s ;
    min-height: 250px;

    &:hover {
        background-color: rgba(0, 66, 37, 1);
        transition: all 300ms linear 0s ;
    }

    .read-more {
        color: #FFFFFF;
        font-size: 38px;
        
    }
`

const PostTitle = styled.div`
    color: #FFFFFF;
    font-family: 'Cormorant Garamond', serif !important;
    align-self: center;
    font-style: italic !important;
    .blog-date {
        font-weight: 600;
        font-size: 18px;
        font-family: 'Cormorant Garamond', serif !important;
        text-align: center;
    }
    .blog-title{
        font-weight: 700;
        text-align: center;
        font-size: 24px !important;
    }
    
    @media (max-width: 450px) {
        .blog-date {
            font-size: 3.5vw;
        }

        .blog-title {
            font-size: 5.5vw;
        }
    }
`

const BottomNewsLink = styled(Link)`
    margin: 0 auto;
    display: inline-block;
    color: rgba(0, 66, 37, 1);
    text-decoration: none;
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
    text-align: center;
    font-size: 38px;

    .newslink{
        font-family: 'Raleway', sans-serif !important;
        font-weight: 700;
        text-align: center;
        font-size: 18px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        padding-bottom 5px;
    }

    &:hover{
        .newslink{
            border-bottom: 2px solid rgba(0, 66, 37, 1);
            transition: all 300ms linear 0s;
        }
    }
`